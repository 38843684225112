import { normalize } from 'normalizr'
import { assign, merge, omit, pickBy, has, isEmpty } from 'lodash-es'

import {
    FETCHED_ARTICLES,
    FETCHED_COUNTRIES,
    FETCHED_INDICES,
    FETCHED_SECURITIES,
    FETCHED_CURRENCIES,
    FETCHED_SPREADS,
    FETCHED_PREFERENCES,
    SAVED_PREFERENCE,
    DELETED_PREFERENCE,
    LOGIN_SUCCESS,
    LOGOUT,
} from '../config/constants'

import {
    article,
    country,
    index,
    security,
    currency,
    spread,
    preference,
    user,
} from '../schemas'

export default (state = {}, action) => {
    switch (action.type) {
        case FETCHED_ARTICLES:
            return action.payload ? merge({}, state, normalize(action.payload, [article]).entities) : state
        case FETCHED_COUNTRIES:
            return action.payload ? assign({}, state, normalize(action.payload, [country]).entities) : state
        case FETCHED_INDICES:
            return action.payload ? assign({}, state, normalize(action.payload, [index]).entities) : state
        case FETCHED_SECURITIES:
            return action.payload ? assign({}, state, normalize(action.payload, [security]).entities) : state
        case FETCHED_CURRENCIES:
            return action.payload ? assign({}, state, normalize(action.payload, [currency]).entities) : state
        case FETCHED_SPREADS:
            return action.payload ? assign({}, state, normalize(action.payload, [spread]).entities) : state
        case FETCHED_PREFERENCES:
            return action.payload ? merge({}, state, normalize(action.payload, [preference]).entities) : state
        case SAVED_PREFERENCE:
            return action.payload ? merge({}, state, normalize(action.payload, preference).entities) : state
        case DELETED_PREFERENCE:
            return action.payload ? assign({}, state, { preferences: omit(state.preferences, action.payload.id) }) : state
        case LOGIN_SUCCESS:
            return action.payload ? assign({}, state, normalize(action.payload, user).entities) : state
        case LOGOUT:
            const newArticles = pickBy(state.articles, article => article.public)
            const newPodcasts = pickBy(state.podcasts, podcast => has(newArticles, podcast.article))
            return {
                ...omit(state, ["preferences","users"]),
                articles: newArticles,
                podcasts: newPodcasts,
            }
        default:
            const userCache = JSON.parse(localStorage.getItem("user") || sessionStorage.getItem("user") || "{}")
            return (
                isEmpty(state) && !isEmpty(userCache)
                ?
                assign({}, state, normalize(userCache, user).entities)
                :
                state
            )
    }
}
