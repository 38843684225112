import { schema } from 'normalizr'

export const podcast = new schema.Entity('podcasts')

export const tag = new schema.Entity('tags')

export const user = new schema.Entity('users')

export const article = new schema.Entity('articles', {
    tags: [tag],
    podcasts: [podcast],
})

export const preference = new schema.Entity('preferences', {
    user: user,
    article: article,
    podcasts: [podcast],
})

export const country = new schema.Entity('countries')

export const index = new schema.Entity('indices')

export const security = new schema.Entity('securities')

export const currency = new schema.Entity('currencies')

export const spread = new schema.Entity('spreads')
