export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'

export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const FETCHED_ARTICLES = 'FETCHED_ARTICLES'
export const FETCH_PREFERENCES = 'FETCH_PREFERENCES'
export const FETCHED_PREFERENCES = 'FETCHED_PREFERENCES'
export const SAVE_PREFERENCE = 'SAVE_PREFERENCE'
export const TOGGLE_PREFERENCE = 'TOGGLE_PREFERENCE'
export const SAVED_PREFERENCE = 'SAVED_PREFERENCE'
export const DELETE_PREFERENCE = 'DELETE_PREFERENCE'
export const DELETED_PREFERENCE = 'DELETED_PREFERENCE'

export const CHECK_ARTICLES = 'CHECK_ARTICLES'
export const CHECKED_ARTICLES = 'CHECKED_ARTICLES'

export const FETCH_GRAPH = 'FETCH_GRAPH'
export const FETCH_USER = 'FETCH_USER'
export const FETCHED_COUNTRIES = 'FETCHED_COUNTRIES'
export const FETCHED_INDICES = 'FETCHED_INDICES'
export const FETCHED_SECURITIES = 'FETCHED_SECURITIES'
export const FETCHED_CURRENCIES = 'FETCHED_CURRENCIES'
export const FETCHED_SPREADS = 'FETCHED_SPREADS'

export const FETCH_FAILED = 'FETCH_FAILED'
export const SAVE_FAILED = 'SAVE_FAILED'
export const DELETE_FAILED = 'DELETE_FAILED'

export const API_ENDPOINT = window._env === 'dev' ? `//${window.location.hostname}:1337` : '/api'
export const GRAPH_ENDPOINT = window._env === 'dev' ? `//${window.location.hostname}:7474` : '/graph'

export const DATASETFIELD_CONDITION = "5d2c964931eff4000ded1f3c" // un elemento tra

export const PREFERENCES = {
    "countries": [
        {
            "template": "5d30158fb8ef62000d98993b",
            "datasetfield": "5e72073be2df8e000f8a62b5", // CountryLabel
        },
        {
            "template": "5d3017e5b8ef62000d98993f",
            "datasetfield": "5e7249be055c5e000f2d603a", // CountryLabel
        },
    ],
    "indices": [{
        "template": "5d3017e5b8ef62000d98993f",
        "datasetfield": "5e7249ad055c5e000f2d6011", // Denominazione
    }],
    "securities": [{
        "template": "5dcc22e47b87bb000fe53da9",
        "datasetfield": "5e708534786a67000f2f3c38", // Denominazione
    }],
    "currencies": [{
        "template": "5e7b8e33f1ebc6000f786eab",
        "datasetfield": "5e79e125bdab8e51712d56cc", // Denominazione
    }],
    "spreads": [{
        "template": "5e7b8efbf1ebc6000f786eaf",
        "datasetfield": "5e735e5d077bda000f1a9ac1", // Denominazione
    }],
}
