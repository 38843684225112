export default {
    type: "dark",
    palette: {
        primary: {
            light: "#8b8b8b",
            main: "#212121",
            dark: "#030303",
        },
        secondary: {
            light: "#fbe7ae",
            main: "#f9cf5f",
            dark: "#f19c38",
        }
    },
    typography: {
        body1: {
            fontFamily: 'sole_text,Georgia,Times,"Times New Roman",serif',
            lineHeight: 1.2,
            fontSize: "1rem",
        },
        body2: {
            fontFamily: 'SoleSans,Arial,"Helvetica Neue",Helvetica,sans-serif',
            fontSize: "1rem",
        },
        subtitle1: {
            fontFamily: 'SoleSans,Arial,"Helvetica Neue",Helvetica,sans-serif',
            fontSize: "0.65rem",
            fontStyle: "italic",
        },
        h1: {
            fontFamily: 'sole_headline,Georgia,Times,"Times New Roman",serif',
            fontSize: "2rem",
            fontWeight: "bold",
        },
        h2: {
            fontFamily: 'sole_headline,Georgia,Times,"Times New Roman",serif',
            fontSize: "1.5rem",
            fontWeight: "bold",
        },
        h3: {
            fontFamily: 'SoleSans,Arial,"Helvetica Neue",Helvetica,sans-serif',
            fontSize: "1.25rem",
        },
    },
    overrides: {
        MuiBottomNavigationAction: {
            root: {
                color: "#8b8b8b",
                "&$selected": {
                  color: "#f9cf5f !important",
                },
            },
        },
    },
}
