import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import classnames from 'classnames'

import {
    articlesSelector,
    tagsAsObjectSelector,
} from '../../selectors'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import {
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Typography,
    Grid,
    Fab,
} from '@material-ui/core'

import {
    Add as AddIcon,
} from '@material-ui/icons'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const { t } = useTranslation()

    const articles = useSelector(articlesSelector)
    const tags = useSelector(tagsAsObjectSelector)

    return (
        <>

            <List dense className={classes.root}>
                {
                    articles.filter(article => article.public).map((article, index) => {
                        const labelId = `checkbox-list-secondary-label-${article.id}`
                        return (
                            <ListItem
                                key={article.id}
                                button
                                className={classnames(classes.item, index ? classes.itemb : "")}
                                component={RouterLink} to={`/podcasts/${article.id}`}
                            >
                                <ListItemAvatar>
                                    <img src={`./images/article-${index % 3 + 1}.png`} className={classes.image} alt="Article cover"></img>
                                </ListItemAvatar>
                                <ListItemText
                                    id={labelId}
                                    primary={<Typography variant="subtitle1">{article.tags.map(t => tags[t].name.toUpperCase()).join(", ")}</Typography>}
                                    secondary={<Typography variant="body1" style={{ fontWeight: "bold" }}>{article.title}</Typography>}
                                />
                            </ListItem>
                        )
                    })
                }
            </List>

            <Grid container justify="center">
                <Grid item xs={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} className={classes.actionButtons}>
                            <Fab
                                color="secondary" aria-label="add"
                                component={RouterLink} to={`/schedules`}
                            >
                                <AddIcon />
                            </Fab>
                        </Grid>
                        <Grid item xs={8} className={classes.actionLabels}>
                            <Typography variant="body2">
                                {t('actions.build')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )

}
