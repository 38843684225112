import {
    FETCH_GRAPH,
    FETCHED_COUNTRIES,
    FETCHED_INDICES,
    FETCHED_SECURITIES,
    FETCHED_CURRENCIES,
    FETCHED_SPREADS,
} from '../config/constants'

export default () => ({
    type: FETCH_GRAPH,
    payload: {
        statements: [
            {
                statement: `MATCH (:StockExchange)-[:COUNTRY]->(n:Country) RETURN n`,
            },
            {
                statement: `MATCH (n:StockMarketIndex)-[:LISTED_IN]->(:StockExchange) RETURN n`,
            },
            {
                statement: `MATCH (n:Security)-[:LISTED_IN]->(:StockExchange) RETURN n`,
            },
            {
                statement: `MATCH (:Currency { code: "EUR" })-[:EXCHANGED_FOR]->(n:CurrencyExchange) RETURN n`,
            },
            {
                statement: `MATCH (n:Spread)-[:LISTED_IN]->(:StockExchange { code: "MTS" }) RETURN n`,
            },
        ],
    },
    meta: {
        statements: [
            { type: FETCHED_COUNTRIES, entity: "countries", values: [] },
            { type: FETCHED_INDICES, entity: "indices", values: [] },
            { type: FETCHED_SECURITIES, entity: "securities", values: [] },
            { type: FETCHED_CURRENCIES, entity: "currencies", values: [] },
            { type: FETCHED_SPREADS, entity: "spreads", values: [] },
        ],
    },
})
