import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import style from './style'
const useStyles = makeStyles(style)

export default ({ title = "" }) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <img className={classes.cover} src="./images/home-cover.png" alt="Cover" />
            <Typography variant="h1" className={classes.title}>{title}</Typography>
        </div>
    )

}
