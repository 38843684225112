export default theme => ({
    root: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(2),
    },
    itemsContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    pickersContainer: {
        "& .MuiPickersStaticWrapper-staticWrapperRoot": {
            width: "100%",
        },
    },
})
