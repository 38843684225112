import { call, put, takeEvery } from 'redux-saga/effects'
import { includes, isEmpty } from 'lodash-es'

import {
    FETCH_GRAPH,
    FETCH_FAILED,
} from '../config/constants'

import Api from '../config/graph'

function* fetchGraph(action) {
    try {
        const response = yield call(Api, action.request, action.payload)
        for (let [i,v] of response.data.results.entries()) {
            yield put({
                type: action.meta.statements[i].type,
                payload: v.data.map(
                    d => ({
                        ...d.row[0],
                        ...d.meta[0],
                        enabled: isEmpty(action.meta.statements[i].values) || includes(action.meta.statements[i].values, d.row[0].label),
                    })
                ),
            })
        }
    } catch (e) {
        yield put({ type: FETCH_FAILED, message: e.message })
    }
}

export default function* graphSaga() {
    yield takeEvery(FETCH_GRAPH, fetchGraph)
}
