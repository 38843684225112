import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { last } from 'lodash-es'

import {
    timeFormat as d3TimeFormat
} from 'd3-time-format'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import {
    articlesAsObjectSelector,
    podcastsAsObjectSelector
} from '../../selectors'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const { t } = useTranslation()

    const params = useParams()

    const articlesAsObject = useSelector(articlesAsObjectSelector)
    const articleId = params.articleId
    const article = articleId ? articlesAsObject[articleId] || {} : {}

    const podcastsAsObject = useSelector(podcastsAsObjectSelector)
    const podcastId = last(article.podcasts)
    const podcast = podcastId ? podcastsAsObject[podcastId] || {} : {}

    const tf = d3TimeFormat('%d %B %Y at %H:%M')

    return (
        <>

            <Typography variant="h4">{article.title}</Typography>

            <Typography variant="subtitle2" gutterBottom>
                {`Generato automaticamente il giorno ${tf(new Date(podcast.timestamp))}`}
            </Typography>

            <audio controls src={`/audio/podcasts/${podcast.audio}`} className={classes.audio}></audio>

            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: podcast.text }} gutterBottom></Typography>

            <Button
                variant="contained" color="primary" className={classes.button}
                component={RouterLink} to="/podcasts"
            >
                {t("podcast.cta")}
            </Button>
        </>
    )

}
