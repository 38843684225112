export default theme => ({
    text: {
        lineHeight: 1.75,
        marginTop: theme.spacing(2),
        "& .field-value": {
            //backgroundColor: theme.palette.secondary.light,
            //padding: `0 ${theme.spacing(1/2)}px`,
            borderBottom: "1px dotted #cacaca",
        },
    },
})
