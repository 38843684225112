export default theme => ({
    root: {
        height: "100%",
        textAlign: "center",
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        "& > *": {
            marginBottom: theme.spacing(4),
        },
    },
    title: {
        color: theme.palette.secondary.main,
    },
    actionButtons: {
        textAlign: "right",
    },
    actionLabels: {
        textAlign: "left",
        color: theme.palette.primary.light,
    },
})
