import { LOGIN_REQUEST } from '../config/constants'

export default (username, password, rememberMe) => ({
    type: LOGIN_REQUEST,
    request: {
        method: 'post',
        url: '/auth/local',
    },
    payload: {
        username,
        password,
        rememberMe,
    }
})
