import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { includes } from 'lodash-es'

import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

import {
    SettingsOutlined as GearIcon,
    FormatListBulleted as ListIcon,
    Schedule as ClockIcon,
    StarBorder as StarIcon,
} from '@material-ui/icons'

import {
    isAuthenticatedSelector,
} from '../../selectors'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const { t } = useTranslation()

    const isAuth = useSelector(isAuthenticatedSelector)

    const pages = ["","settings","podcasts","schedules","recommendations"]

    const params = useParams()
    const [value, setValue] = useState(params.pageId || "")

    useEffect(() => {
        if (includes(pages, params.pageId)) {
            setValue(params.pageId)
        } else {
            setValue("")
        }
    }, [params, pages])

    return (
        <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue)
            }}
            className={classes.appBar}
            showLabels
        >
            <BottomNavigationAction
                label={t('settings.label')} value="settings" icon={<GearIcon />}
                component={RouterLink} to={`/settings`}
            />
            <BottomNavigationAction
                label={t('podcasts.label')} value="podcasts" icon={<ListIcon />}
                component={RouterLink} to={`/podcasts`}
            />
            <BottomNavigationAction
                className={classes[isAuth || "disabled"]}
                label={t('schedules.label')} value="schedules" icon={<ClockIcon />}
                component={RouterLink} to={`/schedules`} disabled={!isAuth}
            />
            <BottomNavigationAction
                label={t('recommendations.label')} value="recommendations" icon={<StarIcon />}
                component={RouterLink} to={`/recommendations`}
            />
        </BottomNavigation>
    )
}
