export default theme => ({
    item: {
        paddingLeft: 0
    },
    itemb: {
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: theme.palette.primary.light,
    },
    selected: {
        backgroundColor: theme.palette.secondary.light,
    },
    number: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "1.5em"
    },
})
