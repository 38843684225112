import { call, put, takeEvery } from 'redux-saga/effects'

import { isEmpty, map, sortBy, isEqual, find } from 'lodash-es'

import {
    FETCH_PREFERENCES,
    FETCHED_PREFERENCES,
    FETCH_FAILED,
    SAVE_PREFERENCE,
    TOGGLE_PREFERENCE,
    SAVED_PREFERENCE,
    SAVE_FAILED,
    DELETE_PREFERENCE,
    DELETED_PREFERENCE,
    DELETE_FAILED,
    
    PREFERENCES,
    DATASETFIELD_CONDITION,
} from '../config/constants'

import Api from '../config/api'

function* fetchPreferences(action) {
    try {
        const response = yield call(Api, action.request)
        yield put({ type: FETCHED_PREFERENCES, payload: response.data })
    } catch (e) {
        yield put({ type: FETCH_FAILED, message: e.message })
    }
}

function* savePreference(action) {
    var conditionedTemplates = []
    try {
        const options = action.payload.options
        
        // Loop on options
        for (let optionId in options) {
            if (!isEmpty(options[optionId])) {
                let values = sortBy(options[optionId]).join(",")
                for (let preference of PREFERENCES[optionId]) {
                    const responseTemplate = yield call(Api, { url: `/templates/${preference.template}` })
                    const template = responseTemplate.data
                    var conditionValues = {}
                    for (let ct of template.conditionedtemplates) {
                        if (ct.conditions.length === 1) {
                            const responseCondition = yield call(Api, { url: `/conditions/${ct.conditions[0]}` })
                            const condition = responseCondition.data
                            if (!isEmpty(condition.value_type)) {
                                let cValues = map(sortBy(condition.value_type.value.split(",")), v => v.trim()).join(",")
                                conditionValues[cValues] = { conditionedtemplate: ct.id, condition: condition.id }
                            }
                        }
                    }
                    if (conditionValues[values]) {
                        conditionedTemplates.push(conditionValues[values].conditionedtemplate)
                    } else {
                        const responseConditions = yield call(
                            Api,
                            { url: `/conditions` },
                            {
                                datasetfield: preference.datasetfield,
                                datasetfieldcondition: DATASETFIELD_CONDITION
                            }
                        )
                        const conditions = responseConditions.data
                        let currentCondition = find(conditions, c => values === map(sortBy(c.value_type.value.split(",")), v => v.trim()).join(","))
                        if (!currentCondition) {
                            const responseNewCondition = yield call(
                                Api,
                                { method: 'post', url: `/conditions` },
                                {
                                    value_type: { type: 'string', value: values },
                                    datasetfield: preference.datasetfield,
                                    datasetfieldcondition: DATASETFIELD_CONDITION,
                                }
                            )
                            currentCondition = responseNewCondition.data
                        }
                        const responseConditionedTemplate = yield call(
                            Api,
                            { method: 'post', url: `/conditionedtemplates` },
                            {
                                description: "Custom content",
                                template: template.id,
                                conditions: [currentCondition.id]
                            }
                        )
                        conditionedTemplates.push(responseConditionedTemplate.data.id)
                    }
                }
            }
        }

        let currentArticle
        if (!isEmpty(conditionedTemplates)) {
            const responseArticles = yield call(Api, { url: `/articles` }, { conditionedtemplates: conditionedTemplates })
            const articles = responseArticles.data
            currentArticle = find(articles, a => isEqual(conditionedTemplates, map(a.conditionedtemplates,"id")))
            if (!currentArticle) {
                const responseArticle = yield call(
                    Api,
                    { method: 'post', url: `/articles` },
                    {
                        title: "Custom content",
                        public: false,
                        description: "",
                        status: "published",
                        conditionedtemplates: conditionedTemplates
                    }
                )
                currentArticle = responseArticle.data
            }
        }

        const responsePreference = yield call(Api, action.request, { ...action.payload, article: currentArticle.id })
        yield put({ type: SAVED_PREFERENCE, payload: responsePreference.data })

    } catch (e) {
        yield put({ type: SAVE_FAILED, message: e.message })
    }
}

function* togglePreference(action) {
    try {
        const response = yield call(Api, action.request, action.payload)
        yield put({ type: SAVED_PREFERENCE, payload: response.data })
    } catch (e) {
        yield put({ type: SAVE_FAILED, message: e.message })
    }
}

function* deletePreference(action) {
    try {
        const response = yield call(Api, action.request)
        yield put({ type: DELETED_PREFERENCE, payload: response.data })
    } catch (e) {
        yield put({ type: DELETE_FAILED, message: e.message })
    }
}

export default function* preferencesSaga() {
    yield takeEvery(FETCH_PREFERENCES, fetchPreferences)
    yield takeEvery(SAVE_PREFERENCE, savePreference)
    yield takeEvery(TOGGLE_PREFERENCE, togglePreference)
    yield takeEvery(DELETE_PREFERENCE, deletePreference)
}
