export default theme => ({
    button: {
        width: "100%",
    },
    audio: {
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
})
