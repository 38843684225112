export default theme => ({
    item: {
        padding: theme.spacing(1),
        backgroundColor: "#fafafa",
        position: "relative",
        marginBottom: theme.spacing(2),
    },
    chip: {
        marginRight: theme.spacing(.75),
        marginBottom: theme.spacing(.75),
        backgroundColor: `white !important`,
        borderRadius: 0,
    },
    checked: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white",
    },
    checkAll: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
})
