import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import { Grid, Fab } from '@material-ui/core'
import { Add as AddIcon, StarBorder as StarIcon } from '@material-ui/icons'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()
    
    const { t } = useTranslation()

    return (
        <div className={classes.root}>
            <header>
                <Typography variant="h1" className={classes.title}>{t('home.title')}</Typography>
                <Typography variant="h2" className={classes.title}>{t('home.subtitle')}</Typography>
            </header>
            <Grid container justify="center">
                <Grid item xs={9}>
                    <Typography variant="body1">{t('home.description')}</Typography>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={10}>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4} className={classes.actionButtons}>
                            <Fab
                                color="secondary" aria-label="add"
                                component={RouterLink} to={`/schedules`}
                            >
                                <AddIcon />
                            </Fab>
                        </Grid>
                        <Grid item xs={8} className={classes.actionLabels}>
                            <Typography variant="body2">
                                {t('actions.build')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4} className={classes.actionButtons}>
                            <Fab
                                color="secondary" aria-label="select"
                                component={RouterLink} to={`/recommendations`}
                            >
                                <StarIcon />
                            </Fab>
                        </Grid>
                        <Grid item xs={8} className={classes.actionLabels}>
                            <Typography variant="body2">
                                {t('actions.select')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

}
