import { SAVE_PREFERENCE } from '../config/constants'

export default (id, data) => ({
    type: SAVE_PREFERENCE,
    request: {
        method: id === "new" ? "post" : "put",
        url: id === "new" ? `/preferences` : `/preferences/${id}`,
    },
    payload: data,
})
