export default theme => ({
    appBar: {
        backgroundColor: theme.palette.primary.dark
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
        textDecoration: "none",
        textAlign: "center"
    },
    disabled: {
        color: theme.palette.primary.main,
    },
})
