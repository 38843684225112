import { call, put, takeEvery } from 'redux-saga/effects'

import { omit } from 'lodash-es'

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    LOGOUT,
} from '../config/constants'

import Api from '../config/api'

function* requestLogin(action) {
    const storage = action.payload.rememberMe ? localStorage : sessionStorage
    try {
        const response = yield call(
            Api,
            action.request,
            {
                identifier: action.payload.username,
                password: action.payload.password,
            }
        )
        if (response.data) {
            let data = omit({ ...response.data.user, jwt: response.data.jwt }, "preferences")
            yield storage.setItem('user', JSON.stringify(data))
            yield put({ type: LOGIN_SUCCESS, payload: data })
        } else {
            yield put({ type: LOGIN_FAILED, message: "login.emptyResponseMessage" })
        }
    } catch (e) {
        yield put({ type: LOGIN_FAILED, message: "login.failedMessage" })
    }
}

function* requestLogout() {
    yield localStorage.removeItem("user")
    yield sessionStorage.removeItem("user")
}

export default function* authSaga() {
    yield takeEvery(LOGIN_REQUEST, requestLogin)
    yield takeEvery(LOGOUT, requestLogout)
}
