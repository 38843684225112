import React from 'react'
import { Link as RouterLink, useRouteMatch, useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import {
    ArrowBack as BackIcon,
    //Search as SearchIcon,
    Home as HomeIcon,
} from '@material-ui/icons'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const match = useRouteMatch()
    const history = useHistory()

    const { t } = useTranslation()

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                {
                    match.url !== "/"
                    &&
                    <>
                        <IconButton
                            edge="start" color="inherit" aria-label="back"
                            onClick={e => history.goBack()}
                        >
                            <BackIcon />
                        </IconButton>
                        <Typography
                            variant="h1" className={classes.title}
                            //component={RouterLink} to={`/`}
                        >
                            {match.url !== "/recommendations" ? t(`${match.params.pageId}.title`) : ""}
                        </Typography>
                        {/*<IconButton
                            className={classes.disabled}
                            color="inherit" aria-label="search"
                            //component={RouterLink} to={`/search`}
                        >
                            <SearchIcon />
                        </IconButton>*/}
                        <IconButton
                            edge="end" color="inherit" aria-label="home"
                            component={RouterLink} to={`/`}
                        >
                            <HomeIcon />
                        </IconButton>
                    </>
                }
            </Toolbar>
        </AppBar>
    )

}
