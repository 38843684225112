import { call, put, takeEvery } from 'redux-saga/effects'

import { filter, isEmpty } from 'lodash-es'

import {
    FETCH_ARTICLES,
    FETCHED_ARTICLES,
    FETCH_FAILED,
} from '../config/constants'

import Api from '../config/api'

function* fetchArticles(action) {
    try {
        const response = yield call(Api, action.request, action.payload)
        yield put({
            type: FETCHED_ARTICLES,
            payload: filter(
                response.data,
                d => !isEmpty(d.podcasts)
            )
        })
    } catch (e) {
        yield put({ type: FETCH_FAILED, message: e.message })
    }
}

export default function* articlesSaga() {
    yield takeEvery(FETCH_ARTICLES, fetchArticles)
}
