export default theme => ({
    root: {
        position: "relative",
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        textAlign: "center"
    },
    cover: {
        width: "100%",
    },
    title: {
        position: "absolute",
        bottom: theme.spacing(6),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
})
