import { isEmpty } from 'lodash-es'

import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from '../config/constants'

export default (state = {}, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { status: action.type, id: action.payload.id, jwt: action.payload.jwt }
        case LOGIN_FAILED:
        case LOGOUT:
            return { status: action.type, message: action.message }
        default:
            const userCache = JSON.parse(localStorage.getItem("user") || sessionStorage.getItem("user") || "{}")
            return (
                isEmpty(state) && !isEmpty(userCache)
                ?
                { status: LOGIN_SUCCESS, id: userCache.id, jwt: userCache.jwt }
                :
                state
            )
    }
}
