import { TOGGLE_PREFERENCE } from '../config/constants'

export default (id, data) => ({
    type: TOGGLE_PREFERENCE,
    request: {
        method: "put",
        url: `/preferences/${id}`,
    },
    payload: data,
})
