import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import {
    Paper,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    FormHelperText,
    IconButton,
    Grid,
    Typography,
    Button,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'

import {
    Alert
} from '@material-ui/lab';

import {
    EmailOutlined as EmailIcon,
    LockOutlined as LockIcon,
    VisibilityOutlined as VisibilityIcon,
    VisibilityOffOutlined as VisibilityOffIcon,
} from '@material-ui/icons'

import loginRequest from '../../actions/requestLogin'

import {
    authSelector,
} from '../../selectors'

import style from "./style"
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const dispatch = useDispatch()

    const [username, setUsername] = useState("")
    const [usernameError, setUsernameError] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [rememberMe, setRememberMe] = useState(true)
    const [isPasswordVisible, setPasswordVisibility] = useState(false)
    const [isAlertVisible, setAlertVisibility] = useState(false)

    const { t } = useTranslation()

    const auth = useSelector(authSelector)

    function handleSubmit(e) {
        e.preventDefault()
        if (!username) setUsernameError(true)
        if (!password) setPasswordError(true)
        if (username && password) dispatch(loginRequest(username, password, rememberMe))
    }

    useEffect(() => {
        setUsernameError(false)
    }, [username])

    useEffect(() => {
        setPasswordError(false)
    }, [password])

    useEffect(() => {
        setAlertVisibility(!!auth.message)
    }, [auth])

    return (
        <Paper elevation={2} className={classes.root}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <Typography variant="body1">{t("login.description")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="username-field">{t("login.emailLabel")}</InputLabel>
                            <OutlinedInput
                                id="username-field"
                                type="text"
                                autoComplete="username"
                                value={username}
                                error={usernameError}
                                onChange={e => setUsername(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        @radio24.it
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            <FormHelperText>{usernameError && t("login.errorLabel")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="password-field">{t("login.passwordLabel")}</InputLabel>
                            <OutlinedInput
                                id="password-field"
                                type={isPasswordVisible ? 'text' : 'password'}
                                autoComplete="current-password"
                                value={password}
                                error={passwordError}
                                onChange={e => setPassword(e.target.value)}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={e => setPasswordVisibility(!isPasswordVisible)}
                                            onMouseDown={e => setPasswordVisibility(!isPasswordVisible)}
                                            edge="end"
                                        >
                                            {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                            <FormHelperText>{passwordError && t("login.errorLabel")}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <FormControlLabel
                                control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(!rememberMe)} name="rememberme-field" />}
                                label={t("login.remembermeLabel")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <Button variant="contained" color="primary" type="submit" fullWidth>{t("login.loginLabel")}</Button>
                    </Grid>
                    {
                        isAlertVisible
                        &&
                        <Grid item xs={12}>
                            <Alert severity="error" onClose={() => { setAlertVisibility(false) }}>{t(auth.message)}</Alert>
                        </Grid>
                    }
                </Grid>
            </form>
        </Paper>
    )
}
