import { FETCH_ARTICLES } from '../config/constants'

export default () => ({
    type: FETCH_ARTICLES,
    request: {
        url: '/articles',
    },
    payload: {
        public: true,
        status: "published",
    },
})
