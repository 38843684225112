import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ReactHowler from 'react-howler'

import { last, find, isEmpty, padStart, map, values, flatten } from 'lodash-es'

import {
    //timeFormat as d3TimeFormat
} from 'd3-time-format'

import {
    articlesAsObjectSelector,
    podcastsAsObjectSelector,
    preferencesSelector,
} from '../../selectors'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Fab, Slider, Button, Chip } from '@material-ui/core'

import {
    PlayArrowOutlined as PlayIcon,
    PauseOutlined as PauseIcon,
    LoopOutlined as LoopIcon,
    SkipPreviousOutlined as PrevIcon,
    SkipNextOutlined as NextIcon,
    ShuffleOutlined as ShuffleIcon,
    VolumeDownOutlined as VolDownIcon,
    VolumeUpOutlined as VolUpIcon,
    VolumeOffOutlined as VolMuteIcon,
} from '@material-ui/icons'

import { Typography } from '@material-ui/core'

import style from './style'
const useStyles = makeStyles(style)

export default ({
    podcastIndex = 0,
    onToggleTranscript = () => { },
}) => {

    const classes = useStyles()

    const { t } = useTranslation()

    const articlesAsObject = useSelector(articlesAsObjectSelector)
    const preferences = useSelector(preferencesSelector)
    const podcastsAsObject = useSelector(podcastsAsObjectSelector)

    const params = useParams()

    //const tf = d3TimeFormat('%d %B %Y at %H:%M')

    const article = articlesAsObject[params.articleId] || {}
    const preference = find(preferences, p => p.article === article.id) || {}
    const podcast = (
        !isEmpty(article.podcasts) || !isEmpty(preference.podcasts)
        ?
        podcastsAsObject[
            isEmpty(article.podcasts)
            ?
            last(preference.podcasts)
            :
            last(article.podcasts)
        ]
        :
        {}
    )

    const audio = useRef(null)

    const [play, setPlay] = useState(false)
    const [src, setSrc] = useState("")
    const [loop, setLoop] = useState(false)
    const [shuffle, setShuffle] = useState(false)
    const [volume, setVolume] = useState(0.5)
    const [currentTime, setCurrentTime] = useState(0)
    const [transcript, setTranscript] = useState(false)

    useEffect(() => {
        let id = 0
        if (audio.current && play) {
            id = setInterval(() => {
                setCurrentTime(audio.current.seek())
            }, 10)
        } else {
            clearInterval(id)
        }
        return () => clearInterval(id)
    }, [play])

    useEffect(() => {
        setSrc(podcast.audio || src)
    }, [params, podcast, src])

    useEffect(() => {
        onToggleTranscript(transcript)
    }, [transcript, onToggleTranscript])

    return (
        !!src
        &&
        <Grid container justify="center" style={{ textAlign: "center" }}>
            <Grid item xs={10}>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <Slider
                            className={classes.seekSlider}
                            value={currentTime-1} min={0} max={Math.round(podcast.duration || 0)} aria-labelledby="seek" color="secondary"
                            onChange={(e, val) => audio.current.seek(val) && !play && setCurrentTime(val)}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center" direction="column">

                    <Grid item xs style={{ alignSelf: "flex-end" }}>
                        <Button color="primary" onClick={e => setTranscript(!transcript)}>
                            <Typography variant="subtitle1">
                                {t(transcript ? "transcript.hide" : "transcript.show")}
                            </Typography>
                        </Button>
                    </Grid>

                    <Grid item xs>
                        <Typography variant="subtitle1">
                            {
                                currentTime
                                ?
                                `${padStart(Math.floor(currentTime / 60),2,0)}:${padStart(Math.floor(currentTime % 60),2,0)}`
                                :
                                "--:--"
                            }
                        /
                        {
                                podcast.duration
                                ?
                                `${padStart(Math.floor(podcast.duration / 60),2,0)}:${padStart(Math.floor(podcast.duration % 60),2,0)}`
                                :
                                "--:--"
                            }
                        </Typography>
                    </Grid>

                    <Grid item xs>
                        <Typography variant="h1">{`Podcast #${podcastIndex}`}</Typography>
                    </Grid>

                    {
                        !!preference
                        &&
                        <Grid item xs className={classes.tagContainer}>
                            {
                                map(flatten(values(preference.options)), value => (
                                    <Chip key={value} size="small" label={value} className={classes.tag} color="primary" />
                                ))
                            }
                        </Grid>    
                    }

                    <Grid item xs>
                        <Typography variant="body1">{preference.title || article.title}</Typography>
                    </Grid>

                </Grid>

                <Grid container spacing={2} alignItems="center" justify="center">
                    <Grid item>
                        <Fab
                            color={loop ? "secondary" : "primary"} aria-label="repeat" size="small"
                            onClick={e => setLoop(!loop)}
                        >
                            <LoopIcon />
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab
                            color="primary" aria-label="previous" size="small"
                            //onClick={e => setPlay(!play)}
                        >
                            <PrevIcon />
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab
                            color="secondary" aria-label="play" size="large"
                            onClick={e => setPlay(!play)}
                        >
                            {play ? <PauseIcon /> : <PlayIcon />}
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab
                            color="primary" aria-label="next" size="small"
                            //onClick={e => setPlay(!play)}
                        >
                            <NextIcon />
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab
                            color={shuffle ? "secondary" : "primary"} aria-label="shuffle" size="small"
                            onClick={e => setShuffle(!shuffle)}
                        >
                            <ShuffleIcon />
                        </Fab>
                    </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        {volume ? <VolDownIcon onClick={e => setVolume(0)} /> : <VolMuteIcon onClick={e => setVolume(0.5)} />}
                    </Grid>
                    <Grid item xs>
                        <Slider
                            value={volume || 0} step={0.1} min={0} max={1} aria-labelledby="volume"
                            onChange={(e, val) => setVolume(val)}
                        />
                    </Grid>
                    <Grid item>
                        <VolUpIcon />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <ReactHowler
                            ref={audio}
                            src={[`/audio/podcasts/${src}`]}
                            playing={play} loop={loop} volume={volume}
                            onEnd={() => setPlay(loop)}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )

}
