import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import {
    isAuthenticatedSelector,
} from '../../selectors'

export default ({ children, ...rest }) => {

    const isAuth = useSelector(isAuthenticatedSelector)

    return (
        <Route
            {...rest}
            render={({ location }) =>
                !isAuth ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/settings",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    )

}
