export default theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        marginBottom: theme.spacing(2),
    },
    item: {
        paddingLeft: 0,
    },
    itemb: {
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: theme.palette.primary.main,
    },
    checkbox: {
        color: theme.palette.secondary.main,
    },
    image: {
        height: "3em",
        marginTop: theme.spacing(1),
    },
    actionButtons: {
        textAlign: "right",
    },
    actionLabels: {
        textAlign: "left",
        color: theme.palette.primary.light,
    },
})
