import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { isEmpty, includes, without } from 'lodash-es'

import customLocale from 'd3-time-format/locale/en-US.json'

import { makeStyles } from '@material-ui/core/styles'

import {
    Grid,
    Typography,
    Chip,
    Button,
    TextField,
} from '@material-ui/core'

import DateFnsUtils from '@date-io/date-fns'

import {
    MuiPickersUtilsProvider,
    TimePicker,
} from '@material-ui/pickers'

import fetchGraph from '../../actions/fetchGraph'
import savePreference from '../../actions/savePreference'

import {
    userSelector,
    preferencesAsObjectSelector,
    countriesSelector,
    indicesSelector,
    securitiesSelector,
    currenciesSelector,
    spreadsSelector,
} from '../../selectors'

import {
    PreferencesItem,
} from '../../components'

import style from "./style"
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const params = useParams()

    const [countriesChecked, setCountriesChecked] = useState([])
    const [indicesChecked, setIndicesChecked] = useState([])
    const [securitiesChecked, setSecuritiesChecked] = useState([])
    const [currenciesChecked, setCurrenciesChecked] = useState([])
    const [spreadsChecked, setSpreadsChecked] = useState([])
    const [time, setTime] = useState(new Date())
    const [daysChecked, setDaysChecked] = useState([])
    const [title, setTitle] = useState("")

    const user = useSelector(userSelector)
    const preferences = useSelector(preferencesAsObjectSelector)
    const countries = useSelector(countriesSelector)
    const indices = useSelector(indicesSelector)
    const securities = useSelector(securitiesSelector)
    const currencies = useSelector(currenciesSelector)
    const spreads = useSelector(spreadsSelector)

    useEffect(() => {
        dispatch(fetchGraph())
    }, [dispatch])

    useEffect(() => {
        const preference = preferences[params.preferenceId]
        if (!isEmpty(preference)) {
            setCountriesChecked(preference.options.countries || countriesChecked)
            setIndicesChecked(preference.options.indices || indicesChecked)
            setSecuritiesChecked(preference.options.securities || securitiesChecked)
            setCurrenciesChecked(preference.options.currencies || currenciesChecked)
            setSpreadsChecked(preference.options.spreads || spreadsChecked)
            setTime(new Date((new Date()).setHours(preference.hours, preference.minutes)))
            setDaysChecked((preference.days || customLocale.days.join(",")).split(","))
            setTitle(preference.title)
        }
    }, [preferences, params])

    function checkDay(day) {
        setDaysChecked([...daysChecked, day])
    }

    function uncheckDay(day) {
        setDaysChecked(without(daysChecked, day))
    }

    function isDayChecked(day) {
        return includes(daysChecked, day)
    }

    function toggleDayCheck(day) {
        isDayChecked(day) ? uncheckDay(day) : checkDay(day)
    }

    function handleSubmit() {
        dispatch(
            savePreference(
                params.preferenceId,
                {
                    title,
                    options: {
                        countries: countriesChecked,
                        indices: indicesChecked,
                        securities: securitiesChecked,
                        currencies: currenciesChecked,
                        spreads: spreadsChecked,
                    },
                    hours: time.getHours(),
                    minutes: time.getMinutes(),
                    days: daysChecked.join(","),
                    user: user.id,
                }
            )
        )
    }

    return (
        <div className={classes.root}>
            <Typography variant="h2" gutterBottom>{t("schedules.topics.title")}</Typography>
            <Typography variant="body1" gutterBottom>{t("schedules.topics.description")}</Typography>
            <Grid container spacing={2} className={classes.itemsContainer}>
                <PreferencesItem
                    title={t("schedules.stockExchanges.title")}
                    description={t("schedules.stockExchanges.description")}
                    items={countries}
                    itemsChecked={countriesChecked}
                    setItemsChecked={setCountriesChecked}
                />
                <PreferencesItem
                    title={t("schedules.stockMarketIndices.title")}
                    description={t("schedules.stockMarketIndices.description")}
                    items={indices}
                    itemsChecked={indicesChecked}
                    setItemsChecked={setIndicesChecked}
                    search={true}
                />
                <PreferencesItem
                    title={t("schedules.stockMarketSecurities.title")}
                    description={t("schedules.stockMarketSecurities.description")}
                    items={securities}
                    itemsChecked={securitiesChecked}
                    setItemsChecked={setSecuritiesChecked}
                    search={true}
                />
                <PreferencesItem
                    title={t("schedules.stockMarketCurrencies.title")}
                    description={t("schedules.stockMarketCurrencies.description")}
                    items={currencies}
                    itemsChecked={currenciesChecked}
                    setItemsChecked={setCurrenciesChecked}
                />
                <PreferencesItem
                    title={t("schedules.stockMarketSpreads.title")}
                    description={t("schedules.stockMarketSpreads.description")}
                    items={spreads}
                    itemsChecked={spreadsChecked}
                    setItemsChecked={setSpreadsChecked}
                />
            </Grid>
            <Typography variant="h2" gutterBottom>{t("schedules.times.title")}</Typography>
            <Grid container spacing={2} className={classes.itemsContainer}>
                <Grid item xs={12} className={classes.pickersContainer}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                            autoOk
                            ampm={false}
                            variant="static"
                            openTo="hours"
                            value={time}
                            onChange={e => setTime(e)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.itemsContainer}>
                {
                    customLocale.days.map(
                        day => (
                            <Grid item xs key={day}>
                                <Chip
                                    label={day[0].toUpperCase()}
                                    variant={isEmpty(daysChecked) || includes(daysChecked, day) ? "default" : "outlined"}
                                    onClick={() => toggleDayCheck(day)}
                                />
                            </Grid>
                        )
                    )
                }
            </Grid>
            <Grid container spacing={1} className={classes.itemsContainer}>
                <Grid item xs>
                    <TextField
                        label={t("schedules.titleLabel")}
                        multiline
                        rowsMax={2}
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Button
                variant="contained" color="primary" fullWidth
                onClick={handleSubmit}
                component={RouterLink} to="/schedules"
            >{t("schedules.save")}</Button>
        </div>
    )

}
