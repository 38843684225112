import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import {
    Grid,
    Typography,
    Fab,
} from '@material-ui/core'

import {
    Add as AddIcon,
} from '@material-ui/icons'

import togglePreference from '../../actions/togglePreference'
import deletePreference from '../../actions/deletePreference'

import {
    preferencesSelector,
} from '../../selectors'

import {
    SchedulesItem,
} from '../../components'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const preferences = useSelector(preferencesSelector)

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {
                    preferences.map(p => (
                        <SchedulesItem
                            key={p.id}
                            id={p.id}
                            title={p.title}
                            hours={p.hours}
                            minutes={p.minutes}
                            days={p.days}
                            disabled={p.disabled}
                            podcast={p.article}
                            onDelete={id => dispatch(deletePreference(id))}
                            onToggle={id => dispatch(togglePreference(id, { disabled: !p.disabled }))}
                        />
                    ))
                }
            </Grid>
            <Grid container justify="center">
                <Grid item xs={10}>
                    <Grid container spacing={3} alignItems="center" className={classes.action}>
                        <Grid item xs={4} className={classes.actionButtons}>
                            <Fab
                                color="secondary" aria-label="add"
                                component={RouterLink} to={`/schedules/new`}
                            >
                                <AddIcon />
                            </Fab>
                        </Grid>
                        <Grid item xs={8} className={classes.actionLabels}>
                            <Typography variant="body2">
                                {t('actions.new')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

}
