import React, { useState } from 'react'

import classnames from 'classnames'

import { includes, without, uniqueId } from 'lodash-es'

import { makeStyles } from '@material-ui/core/styles'

import {
    Grid,
    Typography,
    Chip,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from '@material-ui/core'

import {
    CheckCircleRounded as CheckIconFull,
    CheckCircleOutlineRounded as CheckIconEmpty,
    Cancel as CancelIcon,
} from '@material-ui/icons'

import style from "./style"
const useStyles = makeStyles(style)

export default ({
    title,
    description,
    items,
    itemsChecked,
    setItemsChecked,
    search,
}) => {

    const classes = useStyles()

    const [ id ] = useState(() => uniqueId('preference-'))
    const [searchString, setSearchString] = useState("")

    function checkItem(id) {
        setItemsChecked([...itemsChecked, id])
    }

    function uncheckItem(id) {
        setItemsChecked(without(itemsChecked, id))
    }

    function clearItems() {
        setItemsChecked([])
    }

    function fillItems() {
        setItemsChecked(items.filter(item => item.enabled).map(item => item.label))
    }

    function isItemChecked(id) {
        return includes(itemsChecked, id)
    }

    function areAllItemsChecked() {
        return itemsChecked.length === items.filter(item => item.enabled).length
    }

    function toggleItemCheck(id) {
        isItemChecked(id) ? uncheckItem(id) : checkItem(id)
    }

    return (
        <Grid item xs={12} className={classes.item}>
            {
                areAllItemsChecked()
                ?
                <CheckIconFull
                    className={classes.checkAll}
                    onClick={() => clearItems()}
                />
                :
                <CheckIconEmpty
                    className={classes.checkAll}
                    onClick={() => fillItems()}
                />
            }
            <Typography variant="h3" gutterBottom>{title}</Typography>
            <Typography variant="subtitle1" gutterBottom>{description}</Typography>
            {
                search
                &&
                <FormControl
                    variant="outlined"
                    size="small"
                    margin="dense"
                    fullWidth
                >
                    <InputLabel htmlFor={id}>Search...</InputLabel>
                    <OutlinedInput
                        id={id}
                        value={searchString}
                        onChange={e => setSearchString(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="clear"
                                    onClick={e => setSearchString("")}
                                    edge="end"
                                >
                                    {!!searchString && <CancelIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                        fullWidth
                    />
                </FormControl>
            }
            {
                (
                    search
                    ?
                    items.filter(item => isItemChecked(item.label) || (searchString && includes(item.label.toLowerCase(), searchString.toLowerCase())))
                    :
                    items
                ).map(
                    item => <Chip
                        key={item.id}
                        className={classnames(classes.chip, classes[isItemChecked(item.label) ? "checked" : "unchecked"])}
                        label={item.label}
                        variant="outlined"
                        size="small"
                        disabled={!item.enabled}
                        onClick={() => toggleItemCheck(item.label)}
                    />
                )
            }
        </Grid>
    )

}
