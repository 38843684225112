export default theme => ({
    root: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(2),
    },
    action: {
        marginTop: theme.spacing(2),
    },
    actionButtons: {
        textAlign: "right",
    },
    actionLabels: {
        textAlign: "left",
        color: theme.palette.primary.light,
    },
})
