export default theme => ({
    seekSlider: {
        height: theme.spacing(1),
        padding: 0,
        "& .MuiSlider-rail": {
            height: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
        },
        "& .MuiSlider-track": {
            height: theme.spacing(1),
        },
        "& .MuiSlider-thumb": {
            height: theme.spacing(2)+2,
            width: theme.spacing(2)+2,
            border: `${theme.spacing(1/2)}px solid white`
        },
    },
    tagContainer: {
        display: `flex`,
        overflowX: `auto`,
        paddingBottom: `3em !important`,
        overflowY: `hidden`,
    },
    tag: {
        marginRight: theme.spacing(.25),
        borderRadius: theme.spacing(.25),
    },
})
