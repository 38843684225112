import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { last, find, isEmpty } from 'lodash-es'

import {
    articlesAsObjectSelector,
    podcastsAsObjectSelector,
    preferencesSelector,
} from '../../selectors'

import { makeStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import style from './style'
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()

    const articlesAsObject = useSelector(articlesAsObjectSelector)
    const preferences = useSelector(preferencesSelector)
    const podcastsAsObject = useSelector(podcastsAsObjectSelector)

    const params = useParams()

    const article = articlesAsObject[params.articleId] || {}
    const preference = find(preferences, p => p.article === article.id) || {}
    const podcast = (
        !isEmpty(article.podcasts) || !isEmpty(preference.podcasts)
        ?
        podcastsAsObject[
            isEmpty(article.podcasts)
            ?
            last(preference.podcasts)
            :
            last(article.podcasts)
        ]
        :
        {}
    )

    console.log(podcast.text)
    return (
        <Typography
            variant="body1" className={classes.text}
            dangerouslySetInnerHTML={{__html: podcast.text || ""}}
        ></Typography>
    )

}
