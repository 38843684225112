import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useLocation, useParams } from 'react-router-dom'

import classnames from 'classnames'

import {
    isEmpty,
    startsWith,
    findIndex,
    sortBy,
} from 'lodash-es'

/*import {
    timeFormatDefaultLocale as d3TimeFormatDefaultLocale,
} from 'd3-time-format'*/

//import itTimeFormatLocale from 'd3-time-format/locale/it-IT.json'

import { useTranslation } from 'react-i18next'

import {
    entitiesSelector,
    userSelector,
    articlesSelector,
    isAuthenticatedSelector,
} from './selectors'

import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'

import {
    Home,
    Podcasts,
    Recommendations,
    Login,
    Settings,
    Schedules,
    Preferences,
} from './containers'

import {
    Cover,
    Player,
    Header,
    Footer,
    Transcript,
    ProtectedRoute,
    AnonRoute,
} from './components'

import fetchArticles from './actions/fetchArticles'
import fetchPreferences from './actions/fetchPreferences'

//d3TimeFormatDefaultLocale(itTimeFormatLocale)

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    container: {
        flexGrow: 1,
        overflow: 'hidden',
    },
    dark: {
        backgroundColor: theme.palette.primary.dark
    },
    light: {
        backgroundColor: "white"
    },
    page: {
        height: '100%',
        overflowY: 'auto'
    }
}))

export default function App() {

    const classes = useStyles()
    const location = useLocation()

    const dispatch = useDispatch()

    const params = useParams()

    const { t } = useTranslation()

    const [ transcript, setTranscript ] = useState(false)
    const [ podcastSelected, setPodcastSelected ] = useState(0)

    const entities = useSelector(entitiesSelector)
    const articles = useSelector(articlesSelector)
    const user = useSelector(userSelector)
    const isAuth = useSelector(isAuthenticatedSelector)

    useEffect(() => {
        dispatch(fetchArticles())
    }, [dispatch])

    useEffect(() => {
        isAuth && dispatch(fetchPreferences(user.id))
    }, [dispatch, isAuth])

    useEffect(() => {
        setPodcastSelected(findIndex(sortBy(articles, "public"), article => params.id === article.id)+1)
    }, [params, articles])

    useEffect(() => {
        setTranscript(false)
    }, [params])

    return (
        <div className={classes.root}>
            <Route path="/:pageId?" component={Header} />
            <Route path="/" exact component={Cover} />
            <Route path="/recommendations" exact render={() => <Cover title={t("recommendations.description")}/>} />
            {
                isEmpty(entities)
                ?
                <Container className={classes.container}>
                    <p>Loading...</p>
                </Container>
                :
                <Container className={classnames(
                    classes.container,
                    (
                        startsWith(location.pathname, "/podcasts")
                        ||
                        startsWith(location.pathname, "/login")
                        ||
                        startsWith(location.pathname, "/settings")
                        ||
                        startsWith(location.pathname, "/schedules/")
                    )
                    ?
                    classes.light
                    :
                    classes.dark
                )}>
                    <div className={classes.page}>
                        <Route path="/" exact component={Home} />
                        <AnonRoute path="/login" exact><Login /></AnonRoute>
                        <ProtectedRoute path="/settings" exact><Settings /></ProtectedRoute>
                        <ProtectedRoute path="/schedules" exact><Schedules /></ProtectedRoute>
                        <ProtectedRoute path="/schedules/:preferenceId" exact><Preferences /></ProtectedRoute>
                        <Route path="/podcasts/:articleId?" exact component={transcript ? Transcript : Podcasts} />
                        <Route path="/recommendations" exact component={Recommendations} />
                    </div>
                </Container>
            }
            <Route path="/podcasts/:articleId" exact render={() => <Player podcastIndex={podcastSelected} onToggleTranscript={e => setTranscript(e)} />} />
            <Route path="/:pageId?" component={Footer} />
        </div>
    )

}
