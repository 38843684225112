import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { includes, padStart } from 'lodash-es'

import customLocale from 'd3-time-format/locale/en-US.json'

import { makeStyles } from '@material-ui/core/styles'

import {
    Grid,
    Typography,
    Chip,
    CardHeader,
    Card,
    IconButton,
    Divider,
    CardContent,
    Switch,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

import {
    Schedule as ScheduleIcon,
    MoreVert as MoreVertIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    VolumeUp as VolumeUpIcon,
} from '@material-ui/icons'

import style from "./style"
const useStyles = makeStyles(style)

export default ({
    id,
    title,
    hours,
    minutes,
    days,
    disabled,
    podcast,
    onDelete,
    onToggle,
}) => {

    const classes = useStyles()

    const { t } = useTranslation()

    const [menuElement, setMenuElement] = useState(null)

    return (
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    avatar={<ScheduleIcon />}
                    action={
                        <>
                            <IconButton aria-label="menu" onClick={e => setMenuElement(e.currentTarget)}>
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={menuElement}
                                keepMounted
                                open={!!menuElement}
                                onClose={() => setMenuElement(null)}
                            >
                                {
                                    !!podcast
                                    &&
                                    <MenuItem component={RouterLink} to={`/podcasts/${podcast}`}>
                                        <ListItemIcon className={classes.icon}><VolumeUpIcon fontSize="small" /></ListItemIcon>
                                        <ListItemText primary={t("schedules.listen")} />
                                    </MenuItem>
                                }
                                <MenuItem component={RouterLink} to={`/schedules/${id}`}>
                                    <ListItemIcon className={classes.icon}><EditIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText primary={t("schedules.edit")} />
                                </MenuItem>
                                <MenuItem onClick={() => onDelete(id)}>
                                    <ListItemIcon className={classes.icon}><DeleteIcon fontSize="small" /></ListItemIcon>
                                    <ListItemText primary={t("schedules.delete")} />
                                </MenuItem>
                            </Menu>
                        </>
                    }
                    title={title}
                />
                <Divider />
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="h1">
                                {padStart(hours, 2, 0)}:{padStart(minutes, 2, 0)}
                            </Typography>
                        </Grid>
                        <Grid item><Switch checked={!disabled} onChange={() => onToggle(id)} /></Grid>
                    </Grid>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Typography variant="body1">{t("schedules.repeat")}</Typography>
                        </Grid>
                        {
                            customLocale.days.map(
                                day => (
                                    <Grid item key={day} className={classes.item}>
                                        <Chip
                                            label={day[0].toUpperCase()}
                                            variant={!days || includes(days, day) ? "default" : "outlined"}
                                        />
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )

}
