import axios from 'axios'

import { API_ENDPOINT } from './constants'

export default (request, data) => {
    const userCache = JSON.parse(localStorage.getItem("user") || sessionStorage.getItem("user") || "{}")
    const method = request.method || 'get'
    return axios.request({
        method,
        url: `${API_ENDPOINT}${request.url || "/"}`,
        [method === 'get' ? "params" : "data"]: data,
        headers: userCache.jwt ? { Authorization: `Bearer ${userCache.jwt}` } : {},
    })
}
