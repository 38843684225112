import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import {
    Grid,
    Typography,
    Button,
} from '@material-ui/core'

import {
    Schedule as ScheduleIcon,
    Person as PersonIcon,
    Email as EmailIcon,
    Lock as LockIcon,
} from '@material-ui/icons'

import logoutRequest from '../../actions/requestLogout'

import {
    userSelector,
    preferencesSelector,
} from '../../selectors'

import style from "./style"
const useStyles = makeStyles(style)

export default () => {

    const classes = useStyles()
    
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const user = useSelector(userSelector)
    const preferences = useSelector(preferencesSelector)

    return (
        <div className={classes.root}>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="h1">{user.fullname}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="body1"><PersonIcon /></Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{user.username}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="body1"><EmailIcon /></Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{user.email}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="body1"><LockIcon /></Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">{[...Array(Math.floor(Math.random()*12)+5).keys()].map(x => "*").join("")}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Typography variant="body1"><ScheduleIcon /></Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1">
                        {preferences.filter(p => !p.disabled).length}&nbsp;
                        {t(`settings.podcast${preferences.filter(p => !p.disabled).length > 1 ? "s" : ""}`)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center" className={classes.button}>
                <Grid item xs={12}>
                    <Button
                        variant="contained" color="primary" fullWidth
                        onClick={() => dispatch(logoutRequest())}
                    >{t("settings.logoutLabel")}</Button>
                </Grid>
            </Grid>
        </div>
    )

}
