import { createSelector } from 'reselect'
import { values, isEmpty } from 'lodash'

export const authSelector = state => state.auth || {}
export const entitiesSelector = state => state.entities || {}

export const userSelector = createSelector(
    entitiesSelector,
    authSelector,
    (entities, auth) => entities.users && auth.id ? entities.users[auth.id] : {}
)

export const isAuthenticatedSelector = createSelector(
    userSelector,
    user => !isEmpty(user)
)

export const countriesAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.countries || {}
)

export const indicesAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.indices || {}
)

export const securitiesAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.securities || {}
)

export const currenciesAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.currencies || {}
)

export const spreadsAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.spreads || {}
)

export const preferencesAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.preferences || {}
)

export const preferenceSelector = id => createSelector(
    preferencesAsObjectSelector,
    preferencesAsObject => preferencesAsObject[id] || {}
)

export const articlesAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.articles || {}
)

export const tagsAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.tags || {}
)

export const podcastsAsObjectSelector = createSelector(
    entitiesSelector,
    entities => entities.podcasts || {}
)

export const countriesSelector = createSelector(
    countriesAsObjectSelector,
    countries => values(countries)
)

export const indicesSelector = createSelector(
    indicesAsObjectSelector,
    indices => values(indices)
)

export const securitiesSelector = createSelector(
    securitiesAsObjectSelector,
    securities => values(securities)
)

export const currenciesSelector = createSelector(
    currenciesAsObjectSelector,
    currencies => values(currencies)
)

export const spreadsSelector = createSelector(
    spreadsAsObjectSelector,
    spreads => values(spreads)
)

export const preferencesSelector = createSelector(
    preferencesAsObjectSelector,
    preferences => values(preferences)
)

export const articlesSelector = createSelector(
    articlesAsObjectSelector,
    articles => values(articles)
)

export const tagsSelector = createSelector(
    tagsAsObjectSelector,
    tags => values(tags)
)

export const podcastsSelector = createSelector(
    podcastsAsObjectSelector,
    podcasts => values(podcasts)
)
