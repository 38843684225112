import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { last, isEmpty, find, sortBy, map, padStart } from 'lodash-es'
import classnames from 'classnames'

import {
    timeFormat as d3TimeFormat,
} from 'd3-time-format'

import {
    articlesSelector,
    preferencesSelector,
    podcastsAsObjectSelector,
} from '../../selectors'

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'

import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    IconButton,
} from '@material-ui/core'

import {
    Star as StarIcon,
    Edit as EditIcon,
} from '@material-ui/icons'

import style from './style'
const useStyles = makeStyles(style)

export default function Podcasts() {

    const classes = useStyles()

    const { t } = useTranslation()

    const articles = useSelector(articlesSelector)
    const preferences = useSelector(preferencesSelector)
    const podcastsAsObject = useSelector(podcastsAsObjectSelector)

    const params = useParams()

    const tf = d3TimeFormat('%d %B %Y at %H:%M')

    return (
        <>

            {
                !!articles.length
                ||
                <Typography variant="body1">{t("podcasts.none")}</Typography>
            }

            {
                !!articles.length
                &&
                <List dense className={classes.root}>
                    {
                        map(sortBy(articles, "public"), (article, index) => {
                            const preference = find(preferences, p => p.article === article.id)
                            const podcast = (
                                !isEmpty(article.podcasts) || !isEmpty(preference.podcasts)
                                ?
                                podcastsAsObject[
                                    isEmpty(article.podcasts)
                                    ?
                                    last(preference.podcasts)
                                    :
                                    last(article.podcasts)
                                ]
                                :
                                {}
                            )
                            return (
                                <ListItem
                                    className={classnames(classes.item, index ? classes.itemb : "", params.articleId === article.id ? classes.selected : "")}
                                    key={`${article.id}-${params.articleId}`} button disabled={isEmpty(podcast)}
                                    component={RouterLink} to={params.articleId === article.id ? "/podcasts" : `/podcasts/${article.id}`}
                                >
                                    <ListItemAvatar><Typography variant="body1" className={classes.number}>{index + 1}</Typography></ListItemAvatar>
                                    <ListItemText
                                        primary={preference ? preference.title : article.title}
                                        secondary={
                                            isEmpty(podcast)
                                            ?
                                            `${t("podcasts.notAvailable", { hours: padStart(preference.hours,2,0), minutes: padStart(preference.minutes,2,0) })}`
                                            :
                                            tf(new Date(podcast.timestamp))
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        {
                                            preference
                                            ?
                                            <IconButton
                                                edge="end" aria-label="edit"
                                                component={RouterLink} to={`/schedules/${preference.id}`}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            :
                                            <IconButton edge="end" aria-label="star">
                                                <StarIcon />
                                            </IconButton>
                                        }
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        })
                    }
                </List>
            }
        </>
    )

}
