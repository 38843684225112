import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import entitiesReducer from './entities'
import authReducer from './auth'

export default (history) => combineReducers({
    entities: entitiesReducer,
    auth: authReducer,
    router: connectRouter(history),
})
